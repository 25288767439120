import React from 'react';
import './App.css';

function App() {
  return (
    <div className="coming-soon">
      <h1>Caro cliente,
          Ti informo che La Barberia è attualmente chiusa per preparativi e restyling in vista della grande inaugurazione dell’ 8 Dicembre.
          Per info e prenotazioni chiama oppure messaggio whatsapp.
      </h1>
      <p>La Barberia</p>
    </div>
  );
}

export default App;